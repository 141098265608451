<template>
  <el-dialog
    :visible.sync="dataBankShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="从资料库选择"
    width="700px"
  >
    <div class="center">
      <div class="group-item" v-if="pageType === 'knowledgeBase'">
        <span style="margin-right:10px"><img style="width:5px;height:5px;margin-right:3px;margin-bottom:6px;" src="@/assets/img/icon_required.png" mode="" class="image-item-left-img"></img>添加至分组</span>
        <GlobalTreeSelect
          style="width: 88%"
          v-model="dataForm.addGroupId"
          filterable
          nodeKey="value"
          :treeProps="defaultProps"
          :data="knowledgeBaseTreeList"
        >
        </GlobalTreeSelect>
      </div>
      <div class="file-content">
        <div class="center-left">
          <div class="group-item" >
            <span>资源分组</span>
            <GlobalTreeSelect
              style="width: 310px"
              v-model="dataForm.groupId"
              filterable
              nodeKey="value"
              :treeProps="defaultProps"
              :data="treeData"
              @input="_getBelongGroup"
            >
            </GlobalTreeSelect>
          </div>
          <el-table
            :data="tableData"
            @selection-change="handleSelectionChange"
            style="width: 100%"
            row-key="id"
            ref="multipleTable"
            height="calc(100vh - 540px)"
          >
            <el-table-column
              :reserve-selection="true"
              type="selection"
              width="55"
            >
            </el-table-column>
            <el-table-column prop="fileName" label="文件名称">
            </el-table-column>
            <el-table-column prop="fileTypeValue" label="文件类型">
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="dataForm.currentPage"
            :page-size="dataForm.pageSize"
            layout="total, prev, pager, next"
            :total="dataForm.total"
          >
          </el-pagination>
        </div>
        <div class="center-right">
          <div class="choosed-content">
            <div
              class="item-content"
              v-for="(item, index) in multipleSelection"
              :key="index"
            >
              <div class="item-name">{{ item.fileName }}</div>
              <div class="item-num">
                <!-- <span> 4人</span> -->
                <svg
                  @click="deleteNode(item)"
                  t="1700041639592"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3431"
                  width="8"
                  height="8"
                >
                  <path
                    d="M512 432.512L865.92 78.464a56.192 56.192 0 1 1 79.616 79.616L591.36 512l353.92 353.92a56.064 56.064 0 0 1-61.312 91.776 56.192 56.192 0 0 1-18.176-12.16L512 591.36 158.08 945.536a56.192 56.192 0 1 1-79.488-79.616L432.512 512 78.464 158.08a56.192 56.192 0 1 1 79.616-79.488L512 432.512z"
                    fill="#bfbfbf"
                    p-id="3432"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getResourcelistApi, getTreeApi } from '@/api/resource'
import { courseCatalogueAddApi } from '@/api/course'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import {
  getTreeListApi,
  batchAdditionAPi
} from '@/api/knowledge'

export default {
  components: { GlobalTreeSelect },
  props: {
    dataBankShow: {
      type: Boolean,
      default: false
    },
    courseware: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    },
    notFileType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataForm: {
        parentId: '',
        groupId: '',
        pageSize: 10,
        page: 1,
        total: 10
      },
      groupList: [], // 所属分组
      tableData: [],
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      multipleSelection: [], // 选中的数据
      knowledgeBaseTreeList: []// 知识库分组
    }
  },
  created () {
    this.query()
    this.getTree()
  },
  methods: {
    getTree () {
      // 获取资源分组树形结构
      if (this.pageType === 'knowledgeBase') {
        getTreeListApi({ parentId: 0 }).then(res => {
          if (res.code === 200) {
            this.knowledgeBaseTreeList = res.data || []
          }
        })
      }
      getTreeApi({
        parentId: '0'
      }).then((res) => {
        if (res.code === 200) {
          this.treeData = res.data || []
        }
      })
    },
    query () {
      // 查询
      if (this.courseware == 2) {
        this.dataForm.notFileType = this.notFileType
      }
      getResourcelistApi({ ...this.dataForm }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.dataForm.total = res.object.total
        }
      })
    },

    _getBelongGroup (value) {
      // 分组查询
      this.dataForm.groupId = value
      this.dataForm.page = 1
      this.query()
    },
    handleCurrentChange (val) {
      this.dataForm.page = val
      this.query()
    },
    cancel () {
      this.$parent.dataBankShow = false
    },
    handleSelectionChange (val) {
      // 表格勾选状态更换
      this.multipleSelection = [...val]
    },
    deleteNode (item) {
      this.$nextTick(() => {
        const groupObj = this.tableData.find(obj => {
          return obj.id == item.id
        })
        this.$refs.multipleTable.toggleRowSelection(groupObj, false)
      })
    },
    submitHandle () {
      // 提交
      if (!this.multipleSelection.length) {
        this.$message.error('请选择文件！')
        return
      }
      if (this.pageType === 'knowledgeBase') {
        if (!this.dataForm.addGroupId) {
          this.$message.error('请选择添加分组！')
          return
        }
        batchAdditionAPi({
          fileList: this.multipleSelection,
          groupId: this.dataForm.addGroupId
        }).then((res) => {
          if (res.code === 200) {
            this.$parent.dataBankShow = false
            this.$parent.getQuery()
          }
        })
      } else {
        courseCatalogueAddApi({
          courseware: this.courseware,
          courseId: this.$route.query.id,
          fileList: this.multipleSelection
        }).then((res) => {
          if (res.code === 200) {
            this.$parent.dataBankShow = false
            this.$parent.getCourseCatalogue()
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.header-title {
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
}
.center {
  .tips {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.group-item {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  span {
    width: 80px;
    text-align: right;
    margin-right: 8px;
    color: rgba(0,0,0,0.9) !important;
  }
  input {
    flex: 1;
  }
}
/deep/.el-pagination {
  text-align: right;
  margin-top: 10px;
}
.file-content {
  display: flex;
}
.center-left {
  width: 400px;
  margin-right: 8px;
}
.center-right {
  width: 244px;
  height: 480px;
  background: #f6f7f9;
  border-radius: 4px 4px 4px 4px;
  padding: 8px;
  overflow: auto;
}
.choosed-content {
  .item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .item-name{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }
    .item-num {
      cursor: pointer;
      display: flex;
      align-items: center;
      span {
        margin-right: 8px;
      }
    }
  }
}
/deep/.el-table__cell{
  color: rgba(0,0,0,0.9) !important;
}
</style>
