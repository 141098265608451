<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-19 10:34:48
 * @LastEditors: wenwen
 * @LastEditTime: 2024-02-22 15:11:22
-->
<template>
  <div>
    <div class="set-box">
      <div class="set-box-title">
        <com-title>设置</com-title>
      </div>
      <div class="switch-box">
        <div>允许员工下载课程资料</div>
        <div style="margin-left: 16px">
          <el-switch v-model="courseInfo.switchValue" @change="changeSwitch"> </el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseBaseInforEditApi } from '@/api/course'
import comTitle from '@/components/comTitle/index.vue'
export default {
  props: {
    courseInfo: {
      default: () => {},
      type: Object
    }
  },
  components: {
    comTitle
  },
  data () {
    return {
      switchValue: false
    }
  },
  created () {

  },
  methods: {
    changeSwitch (value) {
      if (value) {
        this.courseInfo.courseAllow = 1
      } else {
        this.courseInfo.courseAllow = 2
      }
      this.courseBaseInforEdit()
    },
    courseBaseInforEdit () {
      courseBaseInforEditApi(this.courseInfo).then(res => {
        if (res.code === 200) {
          this.$emit('uploadDetail')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-box {
  padding-bottom: 430px;
  margin-bottom: 18px;
}
.set-box-title {
  margin-bottom: 18px;
}
.switch-box {
  display: flex;
  align-items: center;
}
</style>
