<template>
  <div class="container-warp">
    <div class="top-box">
      <back-title>编辑课程</back-title>
      <div class="top-box-info">
        <div class="top-box-info-left">
          <img v-if="!courseInfo.cover" src="@/assets/img/Course.png" alt="">
          <img v-else :src="courseInfo.cover" alt="" />
          <div class="top-box-info-left-xx">
            <div class="top-box-info-left-bt">
              {{ courseInfo.courseName
              }}<span v-if="courseInfo.courseType">{{ courseInfo.courseType }}</span>
            </div>
            <div class="top-box-info-left-kc">
              课程编号：{{ courseInfo.courseCode }}
            </div>
            <div class="top-box-info-left-mc">
              {{ courseInfo.createName }}创建于{{ courseInfo.createTime }}
            </div>
          </div>
        </div>
        <!-- <div class="top-box-info-right">
          <el-button
            type="primary"
            plain
            v-if="courseInfo.status === 1"
            @click="_publish(2)"
            >取消发布</el-button
          >
          <el-button
            type="primary"
            v-if="courseInfo.status === 2"
            @click="_publish(1)"
            >立即发布</el-button
          >
        </div> -->
      </div>
    </div>
    <div class="bottom-box">
      <el-tabs
        class="bottom-tab-box"
        v-model="activeName"
        @tab-click="_tabChange"
        :style="{'--font-Color' : fontColor}"
      >
        <el-tab-pane label="基本信息" name="1">
          <BasicInfo ref="basicInfo" :courseInfo="courseInfo" @updatedDetail="_updatedDetail"></BasicInfo>
        </el-tab-pane>
        <!-- 线上课程课程目录 -->
        <el-tab-pane label="课程目录" name="2" v-if="$route.query.type == 1">
          <CourseArrangement
            :courseCatalogueList="courseCatalogueList"
            @choosedDataBank="_choosedDataBank"
            @uploadCourseFile="_uploadCourseFile"
            @uploadCourseCatalogue="uploadCourseCatalogue"
          ></CourseArrangement>
        </el-tab-pane>

        <el-tab-pane label="课程资料" name="3">
          <Trainee
            @uploadCourseCatalogue="uploadCourseCatalogue"
            :courseCatalogueList="courseCatalogueList"
            @choosedDataBank="_choosedDataBank"
            @uploadCourseFile="_uploadCourseFile"
          ></Trainee>
        </el-tab-pane>
        <el-tab-pane label="设置" name="4">
          <Setting :courseInfo="courseInfo" @uploadDetail="_updatedDetail"></Setting>
        </el-tab-pane>
        <el-tab-pane label="操作记录" name="5">
          <OperationRecord />
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 资料库 -->
    <DataBank
    v-if="dataBankShow"
    :dataBankShow="dataBankShow"
    :courseware="courseware"
    notFileType="video"
    />
    <!-- 上传课件 -->
    <UploadFile
    v-if="uploadFileShow"
    :uploadFileShow="uploadFileShow"
    :courseware="courseware"
    />

  </div>
</template>

<script>
import BackTitle from '@/components/backTitle/index.vue'
import BasicInfo from '../components/basicInfo.vue'
import Trainee from '../components/trainee.vue'
import Setting from '../components/setting.vue'
import CourseArrangement from '../components/courseArrangement.vue'
import DataBank from '@/components/dataBank/index.vue'
import UploadFile from '../components/uploadFile.vue'

import OperationRecord from '../components/operationRecord'
import {
  courseDetailApi,
  publishStatusApi,
  getCourseTreeApi,
  getCourseCatalogueApi
} from '@/api/course'
export default {
  components: {
    BackTitle,
    BasicInfo,
    Trainee,
    Setting,
    CourseArrangement,
    DataBank,
    UploadFile,
    OperationRecord
  },
  data () {
    return {
      activeName: '1',
      dataBankShow: false,
      uploadFileShow: false,
      courseCatalogueList: [], // 课程目录
      courseInfo: {},
      courseware: '2', // 区分课程目录、课程资料上传
      fontColor: this.$store.state.settings.theme
    }
  },
  created () {
    this.courseDetail()
    this.getCourseCatalogue()
  },
  mounted () {
    this.fontColor = this.$store.state.settings.theme
  },
  methods: {
    _updatedDetail () {
      this.courseDetail()
    },
    courseDetail () {
      courseDetailApi({
        id: this.$route.query.id
      }).then((res) => {
        if (res.code === 200) {
          this.courseInfo = res.data
          if (this.courseInfo.courseAllow == 1) {
            this.courseInfo.switchValue = true
          } else {
            this.courseInfo.switchValue = false
          }
        }
      })
    },
    getCourseCatalogue () {
      getCourseCatalogueApi({
        courseId: this.$route.query.id,
        type: this.$route.query.type,
        courseware: this.courseware
      }).then(res => {
        if (res.code === 200) {
          this.courseCatalogueList = res.data
        }
      })
    },
    _getCourseTree () {
      // 获取树形接口
      getCourseTreeApi({
        type: this.$route.query.type
      }).then((res) => {
        if (res.code === 200) {
          this.courseGroupList = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    _publish (type) {
      // 取消发布
      publishStatusApi({
        status: type,
        id: this.$route.query.id
      }).then((res) => {
        if (res.code === 200) {
          this.courseDetail()
          this.$message.success(type == 2 ? '取消发布成功' : '发布成功')
        }
      })
    },
    uploadCourseCatalogue () {
      // 编辑课程目录更新接口
      this.getCourseCatalogue()
    },
    _tabChange (value) {
      if (value.label === '课程资料') {
        this.courseware = 2
      } else if (value.label === '课程目录') {
        this.courseware = 1
      }
      this.$refs.basicInfo.editFlag = false
      this.courseDetail()
      this.getCourseCatalogue()
    },

    _choosedDataBank (type) {
      // 从资料库选择
      // 1课程目录,2资料
      this.courseware = type
      this.dataBankShow = true
    },
    _uploadCourseFile (type) {
      // 上传课件
      // 1课程目录,2资料
      this.courseware = type
      this.uploadFileShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  padding: 16px 24px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .top-box-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-box-info-left {
      display: flex;
      img {
        margin-right: 16px;
        width: 120px;
        height: 90px;
        border-radius: 4px;
      }
      .top-box-info-left-xx {
        .top-box-info-left-bt {
          margin: 4px 0;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            padding: 3px 12px;
            margin-left: 16px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 8px 0px 8px 0px;
            background: linear-gradient(to right, #31adf6, #5096ff);
          }
        }
        .top-box-info-left-kc {
          margin-bottom: 14px;
          margin-top: 6px;
          font-size: 14px;
          color: rgba(0,0,0,0.4);
        }
        .top-box-info-left-mc {
          font-size: 12px;
          color: rgba(0,0,0,0.4);
        }
      }
    }
  }
}
/deep/.el-button {
  margin-left: 16px !important;
  font-size: 14px;
  height: 32px;
}
.bottom-box {
  margin-top: 16px;
  padding: 0 24px;
  background-color: #ffffff;
  border-radius: 4px;
  height:  calc(100vh - 320px);
  overflow: hidden;
  .bottom-tab-box {
    /deep/.el-tabs__nav {
      height: 55px;
    }
    /deep/.el-tabs__item {
      line-height: 55px;
    }
  }
}
/deep/.el-tabs__item.is-active{
    color:var(--font-Color)!important;
  }
  /deep/.el-tabs__item{
    color: #737373!important;
  }
</style>
