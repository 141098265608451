<template>
  <el-dialog
    :visible.sync="uploadFileShow"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="编辑文件"
    width="553px"
  >
    <div class="second-content">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        @keyup.enter.native="_submitHandle()"
      >
        <el-upload
        name="files"
          drag
          :headers="uploadHeader"
          :action="uploadAction"
          :on-success="_handleSuccess"
          :file-list="fileList"
          :on-progress="progress"
          :before-upload="beforeUpload"
          multiple
          :accept="courseware==1?'.png, .jpg, .jpeg,.PNG,.JPG,.JPEG,.doc,.docx, .xls, .xlsx, .ppt, .pdf,.pptx,.mp4':'.png, .jpg, .jpeg,.PNG,.JPG,.JPEG,.doc,.docx, .xls, .xlsx, .ppt, .pdf,.pptx'"
        >
          <img
            class="upload-img"
            src="@/assets/img/upload-drag-tigger.png"
            alt=""
          />
        </el-upload>
        <div class="upload-hint">
          <p>严禁上传包含色情、暴力、反动等相关违法信息的文件.</p>
        </div>

        <el-form-item label="是否添加到资料库">
          <el-switch v-model="dataForm.isAddResouce"> </el-switch>
        </el-form-item>
        <el-form-item label="所属分组" prop="group" v-if="dataForm.isAddResouce">
          <GlobalTreeSelect
          style="width: 275px"
          v-model="dataForm.groupId"
          filterable
          nodeKey="value"
          :treeProps="defaultProps"
          :data="treeData"
          @input="_getBelongGroup">
        </GlobalTreeSelect>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" :disabled="uploadLoadFlag" @click="_submitHandle()">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getCookie } from '@/utils/util'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import { getTreeApi, uploadSourseFileApi } from '@/api/resource'
import { courseCatalogueAddApi } from '@/api/course'
import fileType from '@/mixins/fileType'
export default {
  components: { GlobalTreeSelect },
  mixins: [fileType],
  props: {
    uploadFileShow: {
      type: Boolean,
      default: false
    },
    courseware: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uploadHeader: {
        token: getCookie('token')
      },
      uploadAction:
        process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload/big/file?toPdfFlag=True', // 上传附件 链接
      dataForm: {
        isAddResouce: false, // 是否添加到资料库
        group: '', // 所属分组
        parentId: '',
        fileList: []
      },
      dataRule: {
        group: [
          {
            required: true,
            message: '请选择所属分组',
            trigger: 'change'
          }
        ]
      },
      fileList: [],
      treeData: [], // 分组
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      uploadLoadFlag: true
    }
  },
  created () {
    this.getTree()
  },
  methods: {
    getTree () {
      // 获取资源分组树形结构
      getTreeApi({
        parentId: '0'
      }).then((res) => {
        if (res.code === 200) {
          this.treeData = res.data
        }
      })
    },
    _getBelongGroup (value) {
      // 分组查询
      this.dataForm.group = value || 0
      this.dataForm.parentId = value || 0
    },
    cancel () {
      // 取消
      this.$parent.uploadFileShow = false
    },
    _submitHandle () {
      if (this.dataForm.isAddResouce) {
        this.dataForm.type = 1
      } else {
        this.dataForm.type = 1
      }
      const fileList = []
      this.dataForm.fileList.forEach(item => {
        console.log(item)
        fileList.push({
          fileName: item.name,
          fileType: this.matchType(item.name),
          size: item.size,
          fileDuration: item.response.data[0].fileDuration,
          sourceFileUrl: item.response.data[0].url,
          percentage: item.percentage
        })
      })
      // 如果选择添加到资料库需要调资源管理文件上传新增接口
      if (this.dataForm.isAddResouce) {
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            uploadSourseFileApi({
              ...this.dataForm,
              fileList
            }).then(res => {
              if (res.code === 200) {
                // 资源管理新增
              }
            })
          }
        })
      }

      courseCatalogueAddApi({
        courseware: this.courseware,
        courseId: this.$route.query.id,
        ...this.dataForm,
        fileList
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功！')
          this.$parent.uploadFileShow = false
          this.$parent.getCourseCatalogue()
        }
      })
    },
    beforeUpload (file) {
      // 上传前
      if (this.courseware == 2 && this.matchType(file.name) == 'video') {
        this.$message.info('课程资料不能上传视频文件！')
        return false
      }
    },
    _handleSuccess (response, file, fileList) {
      if (this.matchType(file.name) == 'video') {
        return
      }
      if (response.code == 200) {
        const arr = []
        fileList.forEach(item => {
          if (item.response.code === 200) {
            arr.push(item)
          }
        })
        this.dataForm.fileList = arr
        this.fileList = arr
        this.uploadLoadFlag = false
      } else {
        const arr = []
        fileList.forEach(item => {
          if (item.response.code === 200) {
            arr.push(item)
          }
        })
        this.dataForm.fileList = arr
        this.fileList = arr
        this.$message.error(response.message)
      }
    },
    progress (event, file, fileList) {
      this.uploadLoadFlag = true
    }
  }
}
</script>
<style lang="scss" scoped>
.second-content {
  position: relative;
  .upload-hint {
    position: absolute;
    top: 175px;
    left: 0;
  }
}
.second-content /deep/ .el-upload {
  width: 505px;
  height: 172px;
  .upload-img {
    width: 502px;
    height: 172px;
  }
}
/deep/ .el-upload-dragger {
  width: 505px;
  height: 172px;
  border: none;
  img {
  }
}
/deep/.el-form-item__label {
  width: 140px !important;
}
/deep/ .el-upload-list {
  margin-top: 35px;
}
/deep/ .el-upload--text {
  border: none;
}
.upload-hint {
  color: #9a9a9a;
  font-size: 12px;
  margin: 10px 0;
}
</style>
